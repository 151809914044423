<template>
  <div>
      <v-container class="faq-notice-container">
        <div style="display: inline-table;">
          <div class="row" >
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'document-management-for-businesses' }})">
                    <img :src="`${publicPath}assets/blogs/document-management-for-businesses.webp`" alt="Managing documents for businesses" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Efficient Document Management for Businesses</h1>
                    <p>The effective management of documentation is essential for guaranteeing smooth operations and preserving a competitive advantage in today's fast-paced business.</p>
                </v-card>
                </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'jobsite-manager' }})">
                    <img :src="`${publicPath}assets/blogs/jobsite-manager.webp`" alt="Jobsite Manager for site management" style="width:100%;height:350px;object-fit: contain;">
                    <h1> Jobsite Manager: Enhanced Efficiency and Transparency in Jobsite</h1>
                    <p>Technological breakthroughs continue to transform how jobs are planned, completed, and managed in the ever-changing business world.</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'record-time-qr-code' }})">
                    <img :src="`${publicPath}assets/blogs/qr-code-record-time.webp`" alt="qr code using Record TIME" style="width:100%;height:350px;object-fit: contain;">
                    <h1> QR code usage in Record TIME</h1>
                    <p>Record Time leads businesses into the future with its cutting-edge services and QR code functionality. The platform goes above and beyond by offering QR code functionality, allowing users to commence the workflow with a simple scan quickly.</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'plumbing-business-software-australia', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/plumbing-business.webp`" alt="Record TIME for your plumbing business" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Revolutionise Your Plumbing Business with RECORD TIME</h1>
                    <p>Are you a plumbing business owner and want to put yourself on track for growth?</p>
                </v-card>
            </div> 
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'vehicle-safety-checks-and-list-of-pre-start-checklist', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/vehicle-safety-checks.webp`" alt="vehicle safety checks with pre start checklist" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Vehicle Safety Checks: Types of Pre Start Checklist You Can Create Using Record TIME</h1>
                    <p>A vehicle safety checks is a significant procedure that encompasses inspecting and assessing the condition of a vehicle to ensure its safe operation.</p>
                </v-card>
            </div> 

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'types-of-digital-forms-you-can-create-using-record-times-docket-book', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/docket-book-app.webp`" alt="digital form: pre start checklist, property inspection, chemical hazard, employee details" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Types of Digital Forms You Can Create Using Record TIME’s Docket Template Designer</h1>
                    <p>Are you tired of drowning in paperwork at your jobsite? Do you find yourself buried under heaps of physical forms that take up valuable time and space?</p>
                </v-card>
            </div> 
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'employee-time-clock-software', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/employee-time-clock-software.webp`" alt="employee time clock software" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Enhancing Workforce Management in the Cleaning Industry with an Innovative Employee Time Clock Software</h1>
                    <p>If you're still relying on pen and paper or Excel spreadsheets to record your employee's work hours, here's some good news: Record TIME offers a hassle-free, efficient, and accurate solution for your employee's time-tracking needs.</p>
                </v-card>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'best-construction-scheduling-software', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/construction-scheduling-software.webp`" alt="the best construction scheduling software for streamlined project workflow" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Best Construction Scheduling Software for Builders and Contractors</h1>
                    <p>Record TIME is the best construction scheduling software that effectively handles project workflow in the construction industry.</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'pre-start-checklist-template', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/pre-start-checklist-template.webp`" alt="customisable pre start checklist template" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Enhancing Workplace Safety with Customisable Pre Start Checklist Template </h1>
                    <p>Every organisation, regardless of size or sector, should prioritise safety. Strict adherence to safety protocols is vital to prevent accidents...</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'digital-docketing-with-record-time', pageTitle: 'test' }})">
                    <img :src="`${publicPath}assets/blogs/digital-docketing.webp`" alt="digital docketing with Record Time simplifies the paperwork, improves accuracy and transparency
" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Harnessing the Power of Digital Docketing with Record Time</h1>
                    <p>The digitisation of business processes has been an ongoing trend for several years. One of the...</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'business-management-solution' }})">
                    <img :src="`${publicPath}assets/blogs/business-management-solution.webp`" alt="business management solutions for owner operator" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Business Management Solution for Owner Operator</h1>
                    <p>If you own a business and operate machinery such as an Backhoes, Excava...</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'revolutionising-delivery-business-with-proof-of-delivery-software' }})">
                    <img :src="`${publicPath}assets/blogs/proof-of-delivery.webp`" alt="send proof of delivery with record time" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Revolutionising Delivery Business with Record TIME</h1>
                    <p>In the delivery service Industry, proof of delivery is an important element of the delivery process....</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'plant-pre-start-checklist-template' }})">
                    <img :src="`${publicPath}assets/blogs/pre-start-checklist.webp`" alt="prestart checklist with record time" style="width:100%;height:350px;object-fit: contain;">
                    <h1>How to design a plant Pre Start Checklist</h1>
                    <p>Today we will design a basic Plant Pre Start Checklist using template. The checklist will look like the...</p>
                </v-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <v-card elevation="2" shaped style="padding: 20px;" @click="$router.push({ name: 'blogDetail',  params: { id: 'add-all-your-licenses-and-qualifications-in-one-place' }})">
                    <img :src="`${publicPath}assets/blogs/personal-document-management-software.webp`" alt="add all your licenses and qualifications in one place" style="width:100%;height:350px;object-fit: contain;">
                    <h1>Add all your licenses and qualifications in one place</h1>
                    <p>It is common for job sites these days to ask for various qualifications you may have...</p>
                </v-card>
            </div>
            
          </div>
        </div>
    </v-container>
  </div>
</template>

<style lang="scss">

</style>

<script>
// @ is an alias to /src

export default {
  metaInfo(){
    return {
      title: 'Blog',
      titleTemplate: 'Record TIME → %s',
      meta: this.meta
    }
  },
  components: {
      
  },
  mounted(){
    if(window.location.href.match(/www/g) != null){
      this.meta = [
        {name: 'name', content: ''},
        {name: 'description', content: ''},
        // {name: 'robots', content: 'noindex'}
        { rel: 'canonical', href: 'https://recordtime.com.au/blog' }
      ]
    }
    var noscript = document.getElementsByTagName('noscript');
    noscript[0].remove()
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      meta: [],
    }
  },
  methods: {
  }
}
</script>
